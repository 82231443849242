@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top #wrapper .c-slider__catchcopy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

#top #wrapper {
  padding: 120px 0; }
  #top #wrapper .c-slider {
    padding: 0;
    line-height: 0; }
    #top #wrapper .c-slider li {
      width: 100%;
      height: 160px;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      @media screen and (max-width: 568px) {
        #top #wrapper .c-slider li {
          height: 500px; } }
    #top #wrapper .c-slider__catchcopy {
      font-size: 48px;
      line-height: 1.5;
      text-align: center;
      margin: 0;
      width: 70%; }
      @media screen and (max-width: 568px) {
        #top #wrapper .c-slider__catchcopy {
          width: 85%;
          font-size: 30px; } }
    #top #wrapper .c-slider .slick-prev, #top #wrapper .c-slider .slick-next {
      z-index: 1; }
    #top #wrapper .c-slider .slick-prev {
      left: 25px; }
    #top #wrapper .c-slider .slick-next {
      right: 25px; }
  @media screen and (max-width: 768px) {
    #top #wrapper {
      padding: 60px 0; } }
  #top #wrapper #column .l-column__list p {
    font-size: 14px;
    color: #000000;
    line-height: 1.71; }
  #top #wrapper #column .l-column__list img {
    margin-bottom: 10px; }
  #top #wrapper #column .l-column__ttl {
    font-size: 16px;
    padding-left: 22px;
    position: relative;
    margin-bottom: 10px; }
    #top #wrapper #column .l-column__ttl:before {
      content: "\f054";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      position: absolute;
      top: 18%;
      left: 0;
      transform: translateY(-50%);
      font-weight: 600;
      line-height: 1;
      transition: all 0.2s ease;
      font-size: 80%; }
